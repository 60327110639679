/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RiArrowRightSLine, RiArrowLeftSLine, RiFileDownloadLine, RiPrinterLine, RiMailLine, RiShieldCheckLine, RiAlertLine, RiFirstAidKitLine } from "react-icons/ri"

const WorkplaceSafetyPage = () => {
  return (
    <Layout>
      <SEO 
        title="Workplace Safety Policy Template | Comprehensive Safety Guidelines"
        description="Download our customizable workplace safety policy template with hazard prevention, emergency procedures, and compliance guidelines for creating a safe work environment."
        keywords={[
          "workplace safety policy",
          "safety guidelines template",
          "occupational health and safety",
          "hazard prevention policy",
          "emergency response procedures",
          "OSHA compliance guidelines",
          "workplace incident reporting"
        ]}
      />
      
      {/* Hero Section */}
      <section sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}>
            <h1 sx={{
              fontSize: ['2rem', '2.5rem'],
              fontWeight: 800,
              mb: 3,
              color: 'primary'
            }}>
              Workplace Safety Policy Template
            </h1>
            
            <p sx={{
              fontSize: ['1.1rem', '1.25rem'],
              maxWidth: '800px',
              mb: 4,
              lineHeight: 1.5,
              color: 'text'
            }}>
              A comprehensive, customizable policy template to establish guidelines 
              for maintaining a safe and healthy work environment.
            </p>
            
            <div sx={{
              display: 'flex',
              gap: 3,
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}>
              <Link to="#download" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  color: 'white'
                }
              }}>
                Download Template <RiFileDownloadLine />
              </Link>
              
              <button type="button" onClick={() => window.print()} sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: 600,
                border: 'none',
                cursor: 'pointer',
                boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
              }}>
                Print Preview <RiPrinterLine />
              </button>
            </div>
          </div>
        </div>
      </section>
      
      {/* Main Content */}
      <section sx={{
        py: 5,
        bg: 'white'
      }}>
        <div sx={{
          maxWidth: '1080px',
          mx: 'auto',
          px: 3,
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '280px 1fr'],
          gap: 4
        }}>
          {/* Table of Contents Sidebar */}
          <div sx={{
            bg: 'white',
            p: 3,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            alignSelf: 'start',
            position: ['static', 'static', 'sticky'],
            top: '20px',
            display: ['none', 'none', 'block']
          }}>
            <h3 sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
              mb: 3,
              color: 'primary'
            }}>
              Table of Contents
            </h3>
            
            <nav>
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                <li sx={{ mb: 2 }}>
                  <Link to="#introduction" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Introduction
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#hazard-prevention" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Hazard Prevention
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#emergency-procedures" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Emergency Procedures
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#compliance" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Compliance & Training
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#incident-reporting" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Incident Reporting
                  </Link>
                </li>
              </ul>
            </nav>
            
            <div sx={{
              mt: 4,
              pt: 4,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)'
            }}>
              <Link to="#download" sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                <RiFileDownloadLine /> Download Template
              </Link>
            </div>
          </div>
          
          {/* Main Content Area */}
          <div>
            {/* Introduction Section */}
            <div id="introduction" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiShieldCheckLine sx={{ color: 'primary' }} /> Introduction
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  A comprehensive workplace safety policy is essential for creating and maintaining a safe 
                  work environment for all employees. This policy template outlines key components of 
                  workplace safety, including hazard identification and prevention, emergency procedures, 
                  compliance requirements, and incident reporting protocols.
                </p>
                
                <p>
                  This template can be customized to align with your organization's specific needs, 
                  industry, and applicable regulations. It aims to establish clear guidelines for promoting 
                  safety awareness, preventing workplace accidents, and ensuring compliance with relevant 
                  health and safety laws.
                </p>
                
                <div sx={{
                  bg: 'primaryMuted',
                  p: 3,
                  borderRadius: '6px',
                  mt: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'primary'
                  }}>
                    Key Components of an Effective Safety Policy:
                  </h4>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    }
                  }}>
                    <li>Clear safety objectives and management commitment</li>
                    <li>Hazard identification and risk assessment procedures</li>
                    <li>Preventive measures and safety protocols</li>
                    <li>Emergency response procedures</li>
                    <li>Safety training and education requirements</li>
                    <li>Incident reporting and investigation processes</li>
                    <li>Regular policy review and continuous improvement</li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Hazard Prevention Section */}
            <div id="hazard-prevention" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiAlertLine sx={{ color: 'primary' }} /> Hazard Prevention
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name] is committed to identifying and addressing workplace hazards before they 
                  cause harm. This section outlines the procedures for hazard identification, risk assessment, 
                  and implementation of preventive measures to maintain a safe workplace.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 3,
                  color: 'text'
                }}>
                  Hazard Identification & Risk Assessment
                </h3>
                
                <p>
                  [Outline the process for identifying workplace hazards, conducting risk assessments, 
                  and determining appropriate preventive measures. Include the frequency of assessments 
                  and responsible parties.]
                </p>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  my: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    Sample Hazard Assessment Process
                  </h4>
                  
                  <ol sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    }
                  }}>
                    <li>Regular workplace inspections (quarterly at minimum)</li>
                    <li>Job hazard analysis for routine and non-routine tasks</li>
                    <li>Review of safety data sheets (SDS) for hazardous materials</li>
                    <li>Employee reporting of potential hazards</li>
                    <li>Incident investigation findings and corrective actions</li>
                    <li>Risk assessment using severity and probability matrix</li>
                    <li>Implementation of hazard controls according to hierarchy of controls</li>
                  </ol>
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Preventive Measures
                </h3>
                
                <p>
                  [Describe the hierarchy of controls used to address identified hazards, including 
                  elimination, substitution, engineering controls, administrative controls, and personal 
                  protective equipment (PPE).]
                </p>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mt: 3
                }}>
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Engineering Controls
                    </h4>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 1
                      }
                    }}>
                      <li>Machine guards and barriers</li>
                      <li>Ventilation systems</li>
                      <li>Noise reduction measures</li>
                      <li>Ergonomic workstation design</li>
                      <li>Safety interlocks and sensors</li>
                    </ul>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Administrative Controls
                    </h4>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 1
                      }
                    }}>
                      <li>Safe work procedures</li>
                      <li>Job rotation schedules</li>
                      <li>Safety training programs</li>
                      <li>Warning signs and labels</li>
                      <li>Regular safety meetings</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Emergency Procedures Section */}
            <div id="emergency-procedures" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiFirstAidKitLine sx={{ color: 'primary' }} /> Emergency Procedures
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name] has established emergency response procedures to ensure prompt and 
                  appropriate action in case of emergencies such as fires, natural disasters, medical 
                  emergencies, or other critical situations. This section outlines these procedures.
                </p>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  my: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    Emergency Response Plan Elements
                  </h4>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    }
                  }}>
                    <li>
                      <strong>Evacuation Procedures:</strong> [Include evacuation routes, assembly points, 
                      and procedures for accounting for all employees.]
                    </li>
                    <li>
                      <strong>Fire Emergency:</strong> [Outline procedures for fire detection, alarm activation, 
                      evacuation, and fire extinguisher use.]
                    </li>
                    <li>
                      <strong>Medical Emergencies:</strong> [Describe first aid procedures, location of 
                      first aid kits, and how to contact emergency medical services.]
                    </li>
                    <li>
                      <strong>Severe Weather:</strong> [Provide instructions for sheltering during severe 
                      weather events such as tornadoes or hurricanes.]
                    </li>
                    <li>
                      <strong>Chemical Spills:</strong> [Outline containment and clean-up procedures for 
                      chemical spills, including required PPE.]
                    </li>
                  </ul>
                </div>
                
                <p>
                  Emergency contact information, including internal contacts and external emergency 
                  services, should be prominently displayed throughout the workplace. All employees 
                  should be familiar with emergency procedures through regular training and drills.
                </p>
              </div>
            </div>
            
            {/* Compliance & Training Section */}
            <div id="compliance" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  sx={{ color: 'primary' }}
                  aria-hidden="true"
                >
                  <title>Compliance icon</title>
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                  <polyline points="22 4 12 14.01 9 11.01" />
                </svg>
                Compliance & Training
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name] is committed to maintaining compliance with all applicable health and safety 
                  regulations and providing comprehensive safety training to all employees. This section outlines 
                  our approach to regulatory compliance and safety training programs.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 3,
                  color: 'text'
                }}>
                  Regulatory Compliance
                </h3>
                
                <p>
                  [Company Name] adheres to all applicable occupational health and safety regulations, including 
                  but not limited to those established by the Occupational Safety and Health Administration (OSHA), 
                  state agencies, and local authorities.
                </p>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  my: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    Key Compliance Activities
                  </h4>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    },
                    '& li:last-child': {
                      mb: 0
                    }
                  }}>
                    <li>
                      <strong>Regular Compliance Audits:</strong> Conduct regular internal audits to assess 
                      compliance with applicable regulations and identify areas for improvement.
                    </li>
                    <li>
                      <strong>Record Keeping:</strong> Maintain all required safety records, including OSHA logs, 
                      training records, inspection reports, and incident investigations.
                    </li>
                    <li>
                      <strong>Hazard Communication:</strong> Ensure proper labeling, safety data sheets (SDS), 
                      and training for all hazardous materials in the workplace.
                    </li>
                    <li>
                      <strong>Regulatory Reporting:</strong> Submit all required reports to regulatory agencies 
                      within specified timeframes.
                    </li>
                    <li>
                      <strong>Compliance Updates:</strong> Regularly review and update safety policies and procedures 
                      to reflect changes in regulations or workplace conditions.
                    </li>
                  </ul>
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Safety Training Programs
                </h3>
                
                <p>
                  [Company Name] provides comprehensive safety training to all employees to ensure they understand 
                  workplace hazards, safety procedures, and their role in maintaining a safe work environment.
                </p>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mt: 3
                }}>
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      New Employee Orientation
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      All new employees receive safety orientation training that covers:
                    </p>
                    
                    <ul sx={{
                      pl: 4,
                      mt: 2,
                      mb: 0,
                      '& li': {
                        mb: 1
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>Company safety policies and procedures</li>
                      <li>Hazard identification and reporting</li>
                      <li>Emergency procedures and evacuation routes</li>
                      <li>Personal protective equipment requirements</li>
                      <li>Incident reporting procedures</li>
                    </ul>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Job-Specific Training
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      Employees receive specialized training based on their specific job functions:
                    </p>
                    
                    <ul sx={{
                      pl: 4,
                      mt: 2,
                      mb: 0,
                      '& li': {
                        mb: 1
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>Safe operation of equipment and machinery</li>
                      <li>Proper handling of hazardous materials</li>
                      <li>Job-specific safety procedures</li>
                      <li>Ergonomic training for relevant positions</li>
                      <li>Specialized safety certifications as required</li>
                    </ul>
                  </div>
                </div>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  mt: 4
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="20" 
                      height="20" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      sx={{ color: 'primary' }}
                      aria-hidden="true"
                    >
                      <title>Training icon</title>
                      <path d="M12 8v4l3 3" />
                      <circle cx="12" cy="12" r="10" />
                    </svg>
                    Ongoing Safety Training
                  </h4>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    },
                    '& li:last-child': {
                      mb: 0
                    }
                  }}>
                    <li>
                      <strong>Refresher Training:</strong> Regular refresher training is provided to ensure continued 
                      awareness and compliance with safety procedures.
                    </li>
                    <li>
                      <strong>Safety Meetings:</strong> Departmental safety meetings are conducted monthly to discuss 
                      specific safety concerns and improvements.
                    </li>
                    <li>
                      <strong>Drills and Exercises:</strong> Regular emergency drills are conducted to ensure preparedness 
                      for various emergency scenarios.
                    </li>
                    <li>
                      <strong>Training Documentation:</strong> All safety training is documented, including dates, 
                      content, attendees, and instructor information.
                    </li>
                  </ul>
                </div>
                
                <div sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bg: 'primaryMuted',
                  p: 3,
                  mt: 4,
                  borderRadius: '6px'
                }}>
                  <p sx={{
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    fontStyle: 'italic',
                    textAlign: 'center',
                    mb: 0
                  }}>
                    "Safety training is not just a regulatory requirement; it is an investment in our most valuable 
                    asset—our employees."
                  </p>
                </div>
              </div>
            </div>
            
            {/* Incident Reporting Section */}
            <div id="incident-reporting" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  sx={{ color: 'primary' }}
                  aria-hidden="true"
                >
                  <title>Incident Reporting icon</title>
                  <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />
                  <polyline points="13 2 13 9 20 9" />
                </svg>
                Incident Reporting
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name] has established procedures for promptly reporting, documenting, and investigating 
                  workplace incidents, including accidents, injuries, near misses, and hazardous conditions. Proper 
                  incident reporting is essential for preventing future incidents and ensuring regulatory compliance.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 3,
                  color: 'text'
                }}>
                  Incident Reporting Procedure
                </h3>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  my: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    Steps for Reporting an Incident
                  </h4>
                  
                  <ol sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 3,
                      paddingLeft: 1
                    },
                    '& li:last-child': {
                      mb: 0
                    }
                  }}>
                    <li>
                      <strong>Immediate Response</strong>
                      <p sx={{ mt: 1, mb: 0 }}>
                        Address immediate safety concerns, administer first aid if needed, and 
                        seek emergency medical attention if necessary.
                      </p>
                    </li>
                    <li>
                      <strong>Notification</strong>
                      <p sx={{ mt: 1, mb: 0 }}>
                        Notify a supervisor or manager about the incident as soon as possible, 
                        but no later than the end of the work shift.
                      </p>
                    </li>
                    <li>
                      <strong>Incident Report Form</strong>
                      <p sx={{ mt: 1, mb: 0 }}>
                        Complete an incident report form within 24 hours of the incident, providing 
                        detailed information about what happened, when and where it occurred, who was 
                        involved, and any injuries or property damage.
                      </p>
                    </li>
                    <li>
                      <strong>Documentation</strong>
                      <p sx={{ mt: 1, mb: 0 }}>
                        Include witness statements, photographs, sketches, or any other relevant 
                        information that helps document the incident.
                      </p>
                    </li>
                    <li>
                      <strong>Submission</strong>
                      <p sx={{ mt: 1, mb: 0 }}>
                        Submit the completed incident report to the designated safety representative 
                        or Human Resources department.
                      </p>
                    </li>
                  </ol>
                </div>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mt: 4
                }}>
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Types of Reportable Incidents
                    </h4>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 1
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>Work-related injuries and illnesses</li>
                      <li>Property damage incidents</li>
                      <li>Near miss events (incidents that almost occurred)</li>
                      <li>Hazardous conditions or unsafe behaviors</li>
                      <li>Environmental incidents (spills, releases)</li>
                      <li>Security incidents or breaches</li>
                    </ul>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Incident Response Team
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      The incident response team typically includes:
                    </p>
                    
                    <ul sx={{
                      pl: 4,
                      mt: 2,
                      mb: 0,
                      '& li': {
                        mb: 1
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>Department manager or supervisor</li>
                      <li>Safety coordinator or representative</li>
                      <li>Human Resources representative</li>
                      <li>Affected employee(s)</li>
                      <li>Witnesses (if applicable)</li>
                      <li>Executive leadership (for serious incidents)</li>
                    </ul>
                  </div>
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Incident Investigation
                </h3>
                
                <p>
                  All reported incidents are investigated to identify root causes and determine corrective 
                  actions to prevent recurrence. The scope and depth of the investigation depends on the 
                  severity and potential severity of the incident.
                </p>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  mt: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="20" 
                      height="20" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      sx={{ color: 'primary' }}
                      aria-hidden="true"
                    >
                      <title>Investigation icon</title>
                      <circle cx="11" cy="11" r="8" />
                      <line x1="21" y1="21" x2="16.65" y2="16.65" />
                    </svg>
                    Investigation Process
                  </h4>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    },
                    '& li:last-child': {
                      mb: 0
                    }
                  }}>
                    <li>
                      <strong>Scene Assessment:</strong> Secure and assess the incident scene, collecting 
                      physical evidence and taking photographs before conditions change.
                    </li>
                    <li>
                      <strong>Interviews:</strong> Conduct interviews with the affected employee(s), witnesses, 
                      and others who may have relevant information.
                    </li>
                    <li>
                      <strong>Root Cause Analysis:</strong> Identify the underlying causes of the incident, 
                      looking beyond immediate causes to system and process failures.
                    </li>
                    <li>
                      <strong>Corrective Actions:</strong> Develop and implement corrective actions to address 
                      root causes and prevent similar incidents in the future.
                    </li>
                    <li>
                      <strong>Follow-up:</strong> Monitor the effectiveness of corrective actions and make 
                      adjustments as needed.
                    </li>
                  </ul>
                </div>
                
                <div sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  mt: 4
                }}>
                  <div sx={{
                    width: '100%',
                    maxWidth: '600px',
                    p: 3,
                    bg: 'primaryMuted',
                    borderRadius: '6px',
                    mb: 3
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary',
                      textAlign: 'center'
                    }}>
                      Non-Retaliation Policy
                    </h4>
                    
                    <p sx={{
                      textAlign: 'center',
                      mb: 0
                    }}>
                      [Company Name] strictly prohibits retaliation against any employee for reporting a workplace 
                      incident, hazard, or safety concern. Employees who report incidents or participate in incident 
                      investigations are protected from any form of retaliation.
                    </p>
                  </div>
                  
                  <p sx={{
                    fontWeight: 500,
                    fontSize: '1.05rem',
                    mb: 0
                  }}>
                    Remember: <span sx={{ fontStyle: 'italic' }}>Reporting incidents is not about assigning blame—it's about learning 
                    and preventing future occurrences.</span>
                  </p>
                </div>
              </div>
            </div>
            
            {/* Download Section */}
            <div id="download" sx={{
              bg: 'primaryMuted',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mt: 5
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Download Full Workplace Safety Policy Template
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                mb: 3,
                lineHeight: 1.6
              }}>
                Get the complete, customizable template with all sections to implement a comprehensive 
                workplace safety policy for your organization. The full template includes:
              </p>
              
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0,
                mb: 3,
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                gap: 2
              }}>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> MS Word & PDF formats
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Hazard assessment forms
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Incident report templates
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Emergency response checklists
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Safety inspection guidelines
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> OSHA compliance resources
                </li>
              </ul>
              
              <div sx={{
                display: 'flex',
                gap: 3,
                flexWrap: 'wrap'
              }}>
                <Link to="/contact" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    color: 'white'
                  }
                }}>
                  Download Full Template <RiFileDownloadLine />
                </Link>
                
                <Link to="/contact" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                  }
                }}>
                  Request Customization <RiMailLine />
                </Link>
              </div>
            </div>
            
            {/* Navigation Links */}
            <div sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 4,
              pt: 3,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)'
            }}>
              <Link to="/policies/leave-policy" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                <RiArrowLeftSLine /> Previous: Leave & Time Off Policy
              </Link>
              
              <Link to="/policies" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                Back to All Policies <RiArrowRightSLine />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default WorkplaceSafetyPage